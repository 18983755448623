import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getRefundRulesList: createCancelTokenHandler('getRefundRulesList')
}

export function getRefundRulesList (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/club/refund-rules${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getRefundRulesList'].handleRequestCancellation().token
  })
}


export function getRefund (id) {
  return axios({
    url: `v1/admin/club/refund-rules/${id}`,
    method: 'get'
  })
}

export function editRefund (id, payment) {
  return axios.patch(`v1/admin/club/refund-rules/${id}`, payment)
}
